import { AppProps } from "next/app";
import { Provider } from "react-redux";
import store from "@/stores";
import "@/styles/app.scss";
import "@/styles/iconfont/style.css";
import "swiper/css";
import { DefaultSeo } from "next-seo";
import FirebaseAuth from "@/components/FirebaseAuth";
import { useRouter } from "next/router";
import { useEffect } from "react";
import dynamic from "next/dynamic";
import useAnalytics from "@/hooks/useAnalytics";
import { PUBLIC_URL } from "@/config/env";

const DynamicProgress = dynamic(() => import("@/components/TheProgress"));
const DynamicSnackbar = dynamic(() => import("@/components/Snackbar"));
const DynamicModalLimitFunction = dynamic(
  () => import("@/components/ModalLimitFunction"),
);

function App({ Component, pageProps }: AppProps): JSX.Element {
  const router = useRouter();
  const { event } = useAnalytics();

  // for facebook pixel
  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("180300904283308");
        ReactPixel.pageView();

        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  useEffect(() => {
    const keyword = router.query.keyword;

    const handleRouterChange = () => {
      if (keyword) {
        event("view_search_results", { search_term: keyword });
      }
    };

    router.events.on("routeChangeComplete", handleRouterChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouterChange);
    };
  }, [router.events, router.query, event]);

  return (
    <>
      <DefaultSeo
        defaultTitle="TECTURE（テクチャー） | 空間デザイン情報検索サービス"
        titleTemplate="%s | TECTURE（テクチャー）"
        description="TECTUREは、建築家やデザイナーの事例から、家具・建材情報検索・メーカーへの連絡がワンストップで可能なサービスです。"
        openGraph={{
          url: PUBLIC_URL,
          images: [{ url: `${PUBLIC_URL}/ogp.png` }],
          site_name: "TECTURE",
          locale: "ja_JP",
          type: "website",
        }}
        twitter={{
          cardType: "summary_large_image",
        }}
      />
      <Provider store={store}>
        <FirebaseAuth>
          <Component {...pageProps} />
        </FirebaseAuth>
        <DynamicProgress />
        <DynamicSnackbar />

        <DynamicModalLimitFunction />
      </Provider>
    </>
  );
}

export default App;
