export const RequiredInputMessage = "必須入力項目です";
export const RequiredSelectMessage = "必須選択項目です";
export const RequiredAgreeMessage =
  "利用規約とプライバシーポリシーに同意してください";
export const WrongPatternEmailMessage = "無効なメールアドレスの形式です";
export const WrongPatternPasswordMessage =
  "英数字とアンダースコアの組み合わせで入力してください";

export const getMinLengthMessage = (minLength: number): string => {
  return `${minLength}文字以上で入力してください`;
};

export const getMaxLengthMessage = (maxLength: number): string => {
  return `${maxLength}文字以内で入力してください`;
};

export const getProAccountMessage = (label: string): string => {
  return `選択した${label}は、PROアカウントでの登録はできません`;
};

export const getWrongPatternMessage = (label: string): string => {
  return `無効な${label}の形式です`;
};
