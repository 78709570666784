import JSSHA from "jssha";

export class Signature {
  private _value: string;
  private _time: number;

  constructor(apiKey: string) {
    const shaObj = new JSSHA("SHA-256", "TEXT");
    const date = new Date();

    this._time = Math.floor(date.getTime() / 1000);

    const sig = `key=${apiKey}&timestamp=${this._time}`;
    shaObj.update(sig);

    this._value = shaObj.getHash("B64");
  }

  get value(): string {
    return this._value;
  }

  get time(): number {
    return this._time;
  }
}
