import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { hideSnackbar, showSnackbar } from "@/stores/snackbar";
import { SnackbarSeverity } from "@/components/Snackbar";

interface UseSnackbarResponse {
  show: ShowSnackbar;
  hide: VoidFunction;
}

type ShowSnackbar = (message: string, severity: SnackbarSeverity) => void;

const useSnackbar = (): UseSnackbarResponse => {
  const dispatch = useDispatch();
  const show = useCallback(
    (message: string, severity: SnackbarSeverity) => {
      dispatch(
        showSnackbar({
          severity: severity,
          message: message,
        }),
      );
    },
    [dispatch],
  );

  const hide = useCallback(() => {
    dispatch(hideSnackbar());
  }, [dispatch]);

  return {
    show,
    hide,
  };
};
export default useSnackbar;
