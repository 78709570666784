import {
  BASE_URL,
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
} from "@/config/env";
import { initializeApp } from "firebase/app";
import {
  connectAuthEmulator,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

const config = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
};

initializeApp(config);
const auth = getAuth();

if (BASE_URL.match(/localhost/)) {
  connectAuthEmulator(auth, "http://localhost:9099");

  if (typeof window !== "undefined") {
    window["cypress"] = {
      login: (email: string, password: string) => {
        return signInWithEmailAndPassword(auth, email, password);
      },
      logout: () => {
        return signOut(auth);
      },
    };
  }
}

export { auth };
