import { UserResponse } from "@/web-client";
import { createSlice } from "@reduxjs/toolkit";

type State = {
  joinUser: UserResponse;
};

const InitialState: Partial<State> = {};

const slice = createSlice({
  name: "teamJoinRequest",
  initialState: InitialState as State,
  reducers: {
    setJoinUser(state, action: { payload: UserResponse }) {
      return { ...state, joinUser: action.payload };
    },
    clearJoinUser() {
      return {} as State;
    },
  },
});

export const { setJoinUser, clearJoinUser } = slice.actions;
export const joinRequestReducer = slice.reducer;
