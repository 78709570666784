export const DefaultErrorMessage =
  "エラーが発生しました。しばらく経ってから、再度お試しください。";

export const WrongUserAuthInputErrorMessage =
  "メールアドレスおよびパスワードをご確認ください。";

export const NonAdminUserErrorMessage = "ログインができないユーザーです。";

export const EmailAlreadyInUseErrorMessage =
  "このメールアドレスは利用されています。";
