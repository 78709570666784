import { useCallback } from "react";

interface UseAnalyticsReturn {
  pageView(path?: string, title?: string): void;
  event(eventName: string, eventParams?: unknown): void;
  selectContent(
    contentType?: string,
    itemId?: string,
    eventParams?: unknown,
  ): void;
}

const useAnalytics = (makerId?: number): UseAnalyticsReturn => {
  const event = useCallback(
    (eventName, eventParams = {}) => {
      const params = makerId ? { maker_id: makerId } : {};
      params["send_to"] = "ga4";
      globalThis.gtag("event", eventName, Object.assign(params, eventParams));
    },
    [makerId],
  );

  const pageView = useCallback(
    (path?: string, title?: string) => {
      event("page_view", {
        page_path: path || window.location.pathname,
        page_title: title || document.title,
      });
    },
    [event],
  );

  const selectContent = useCallback(
    (contentType?: string, itemId?: string, eventParams = {}) => {
      event("select_content", {
        content_type: contentType,
        item_id: itemId,
        ...eventParams,
      });
    },
    [event],
  );

  return {
    pageView,
    event,
    selectContent,
  };
};

export default useAnalytics;
