import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SignUpType } from "../types/SignUpType";

type State = SignUpType;

const InitialState: Partial<State> = {};

const slice = createSlice({
  name: "signUp",
  initialState: InitialState as State,
  reducers: {
    setSignUpData(state, action: PayloadAction<Partial<State>>) {
      return { ...state, ...action.payload };
    },
    clearSignUpData() {
      return {} as State;
    },
  },
});

export const { setSignUpData, clearSignUpData } = slice.actions;
export const signUpReducer = slice.reducer;
