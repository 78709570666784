import { SnackbarSeverity } from "@/components/Snackbar";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  hidden: boolean;
  message: string;
  severity: SnackbarSeverity;
}

type SnackbarPayloadAction = {
  message: string;
  severity: SnackbarSeverity;
};

const slice = createSlice({
  name: "snackbar",
  initialState: {
    hidden: true,
    message: "",
    severity: "success",
  } as State,
  reducers: {
    showSnackbar(state, action: PayloadAction<SnackbarPayloadAction>) {
      state.hidden = false;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },
    hideSnackbar(state) {
      state.hidden = true;
    },
  },
});

export const { showSnackbar, hideSnackbar } = slice.actions;
export const snackbarReducer = slice.reducer;
