import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer, { RootState } from "@/stores/rootReducer";
import {
  PersistConfig,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { persistStore } from "redux-persist";
import storage from "./storage";
import { useDispatch } from "react-redux";

const persistConfig: PersistConfig<RootState> = {
  key: "tecture",
  storage,
  whitelist: ["history", "cart"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export { persistor };
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
