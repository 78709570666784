import { useCallback, useEffect, useState } from "react";

export interface Disclosure {
  isOpen: boolean;
  openHandler(): void;
  closeHandler(): void;
  toggle: () => void;
}

const useDisclosure = (initOpen = false): Disclosure => {
  const [isOpen, setIsOpen] = useState(initOpen);
  const toggle = useCallback(() => setIsOpen((prev) => !prev), []);
  useEffect(() => setIsOpen(initOpen), [initOpen]);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return {
    isOpen,
    toggle,
    openHandler: open,
    closeHandler: close,
  };
};

export default useDisclosure;
