import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import client from "@/utils/api/client";
import { MyUserResponse } from "@/web-client/api";
import { AuthProvider } from "@/domain/values/AuthProvider";

interface State {
  token: string;
  user?: MyUserResponse;
  isAuthenticated: boolean;
  isChanged: boolean;
  emailVerified: boolean;
  authUser?: AuthUser;
}

export interface AuthUser {
  displayName?: string;
  email: string;
  providers: AuthProvider[];
}

const initialState = {
  token: "",
  isChanged: false,
  isAuthenticated: false,
  emailVerified: true,
} as State;

export const fetchMe = createAsyncThunk<MyUserResponse, undefined>(
  "auth/fetchMe",
  async () => {
    const res = await client.usersMeGet();

    return res.data;
  },
);

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    setUser(state, action: PayloadAction<MyUserResponse>) {
      state.user = action.payload;
    },
    setIsChanged(state, action: PayloadAction<boolean>) {
      state.isChanged = action.payload;
    },
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    setEmailVerified(state, action: PayloadAction<boolean>) {
      state.emailVerified = action.payload;
    },
    setAuthUser(state, action: PayloadAction<AuthUser>) {
      state.authUser = action.payload;
    },
    clear(state) {
      state.isAuthenticated = false;
      state.isChanged = true;
      state.emailVerified = true;
      state.token = "";
      state.user = null;
      state.authUser = null;
    },
    clearAuthUser(state) {
      state.authUser = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMe.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    });
  },
});

export const {
  setToken,
  setUser,
  setIsChanged,
  setIsAuthenticated,
  setEmailVerified,
  setAuthUser,
  clear,
  clearAuthUser,
} = slice.actions;
export const authReducer = slice.reducer;
