import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AddableMaterialPin,
  AddableNotePin,
  AddableProductVariationPin,
  EditableMaterialPin,
  isProductVariationPin,
} from "@/features/pin/hooks/useEditPins";

interface State {
  keywords: string[];
  pins: (AddableProductVariationPin | AddableNotePin)[];
  materialPins: AddableMaterialPin[];
}

const initialState = {
  keywords: [],
  pins: [],
  materialPins: [],
} as State;

const slice = createSlice({
  name: "history",
  initialState,
  reducers: {
    addKeyword(state, action: PayloadAction<string>) {
      state.keywords = [action.payload, ...state.keywords]
        .filter((x, i, self) => {
          return self.indexOf(x) === i;
        })
        .slice(0, 8);
    },
    addPinHistory(
      state,
      action: PayloadAction<AddableProductVariationPin | AddableNotePin>,
    ) {
      state.pins = [action.payload, ...(state.pins || [])]
        .filter((x, i, self) => {
          return (
            self.findIndex((v) => {
              if (isProductVariationPin(v) && isProductVariationPin(x)) {
                return v.productVariation.id == x.productVariation.id;
              }

              if (!isProductVariationPin(v) && !isProductVariationPin(x)) {
                return (
                  v.maker?.id == x.maker?.id &&
                  v.makerText == x.makerText &&
                  v.text == x.text
                );
              }
            }) === i
          );
        })
        .slice(0, 40);
    },
    addMaterialPinHistory(
      state,
      action: PayloadAction<AddableMaterialPin | EditableMaterialPin>,
    ) {
      state.materialPins = [
        action.payload,
        ...(state.materialPins || []),
      ].slice(0, 40);
    },
    clearKeywords(state) {
      state.keywords = [];
    },
  },
});

export const {
  addKeyword,
  clearKeywords,
  addPinHistory,
  addMaterialPinHistory,
} = slice.actions;
export const historyReducer = slice.reducer;
