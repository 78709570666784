import Dialog from "@/components/Dialog";
import IconWithScreenName from "@/components/IconWithScreenName";
import { TeamResponse, UserResponse } from "@/web-client";
import { FC } from "react";

interface Props {
  isOpen: boolean;
  close: () => void;
  team: TeamResponse;
  user: UserResponse;
}

const DialogApproveJoinRequestTeam: FC<Props> = ({
  isOpen,
  close,
  team,
  user,
}): JSX.Element => {
  return (
    <Dialog isOpen={isOpen} onClose={close} title="参加承認完了">
      <div className="space-y-16">
        <p className="text-sm">
          以下のユーザーの「{team.name}」への参加を承認しました。
        </p>
        <IconWithScreenName user={user} />
      </div>
    </Dialog>
  );
};

export { DialogApproveJoinRequestTeam };
