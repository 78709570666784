import { createSlice } from "@reduxjs/toolkit";

interface State {
  hidden: boolean;
}

const initialState = {
  hidden: true,
} as State;

const slice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    showProgress(state) {
      state.hidden = false;
    },
    hideProgress(state) {
      state.hidden = true;
    },
  },
});

export const { showProgress, hideProgress } = slice.actions;
export const progressReducer = slice.reducer;
