import {
  MakerResponse,
  MaterialResponse,
  NotePinParameter,
  PostPinResponse,
  ProductVariationPinParameter,
  ProductVariationWithProductResponse,
} from "@/web-client/api";
import { IPosition } from "@/domain/values/IPosition";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { addMaterialPinHistory, addPinHistory } from "@/stores/history";

export type AddableProductVariationPin = {
  productVariation: ProductVariationWithProductResponse;
};

export type EditableProductVariationPin = {
  id?: number;
} & AddableProductVariationPin &
  IPosition;

export type AddableNotePin = {
  maker?: MakerResponse;
  makerText?: string;
  text?: string;
};

export type EditableNotePin = {
  id?: number;
} & AddableNotePin &
  IPosition;

export type AddableMaterialPin = {
  material: MaterialResponse;
  notes?: string;
};

export type EditableMaterialPin = {
  id?: number;
} & AddableMaterialPin &
  IPosition;

export type AddablePin =
  | AddableProductVariationPin
  | AddableNotePin
  | AddableMaterialPin;
export type EditablePin =
  | EditableProductVariationPin
  | EditableNotePin
  | EditableMaterialPin;
export type AddPinHandler = (pin: AddablePin) => void;
export type AddMaterialPinHandler = (pin: AddableMaterialPin) => void;
export type PinParameter = ProductVariationPinParameter | NotePinParameter;

interface UseEditPinsReturn {
  addPin: AddPinHandler;
  deletePin(index: number): void;

  resetPins(): void;
  setPosition(position: IPosition): void;

  pins: EditablePin[];
  pinParams: PinParameter[];
}

export const isProductVariationPin = (
  pin: EditablePin | AddablePin,
): pin is EditableProductVariationPin | AddableProductVariationPin => {
  return "productVariation" in pin;
};

export const isMaterialPin = (
  pin: EditablePin | AddablePin,
): pin is EditableMaterialPin | AddableMaterialPin => {
  return "material_id" in pin || "material" in pin;
};

export const isNotePin = (
  pin: EditablePin | AddablePin,
): pin is EditableNotePin | AddableNotePin => {
  return "maker" in pin;
};

const useEditPins = (
  defaultPins: PostPinResponse[] = [],
): UseEditPinsReturn => {
  const dispatch = useDispatch();
  // 編集中のピンの位置
  const [position, setPosition] = useState<IPosition>(null);
  const [pins, setPins] = useState<EditablePin[]>([]);

  const setDefaultPins = useCallback(() => {
    if (defaultPins.length == 0) return;

    setPins(
      defaultPins.map((pin) => {
        if (pin.material_pin) {
          return {
            id: pin.id,
            material:
              pin.material_pin.materials[pin.material_pin.materials.length - 1],
            notes: pin.material_pin.notes,
            ...pin.position,
          };
        } else if (pin.product_variation) {
          return {
            id: pin.id,
            productVariation: pin.product_variation,
            ...pin.position,
          };
        } else {
          return {
            id: pin.id,
            maker: pin.note_pin.maker,
            makerText: pin.note_pin.maker_text,
            text: pin.note_pin.text,
            ...pin.position,
          };
        }
      }),
    );
  }, [setPins, defaultPins]);

  useEffect(() => setDefaultPins(), [setDefaultPins]);

  const addPin = useCallback<AddPinHandler>(
    (pin) => {
      setPins([...pins, { ...pin, ...position }]);

      if (isMaterialPin(pin)) {
        dispatch(addMaterialPinHistory(pin));
      } else {
        dispatch(addPinHistory(pin));
      }
    },
    [setPins, pins, position],
  );

  const deletePin = useCallback(
    (index: number) => {
      const pin = pins[index];
      const target = isProductVariationPin(pin)
        ? pin.productVariation.full_name
        : isMaterialPin(pin)
        ? `素材: ${pin.material.name}`
        : pin.text;

      if (confirm(`${target}を削除しますか？`)) {
        setPins((prev) => {
          const clone = prev.concat();
          clone.splice(index, 1);

          return clone;
        });
      }
    },
    [pins, setPins],
  );

  const pinParams = useMemo<PinParameter[]>(() => {
    const productPinParams = pins.map<PinParameter>((pin) => {
      const params = { id: pin.id, position_x: pin.x, position_y: pin.y };

      return isProductVariationPin(pin)
        ? { ...params, product_variation_id: pin.productVariation.id }
        : isMaterialPin(pin)
        ? {
            ...params,
            notes: pin.notes,
            material_id: pin.material.id,
          }
        : {
            ...params,
            maker_id: pin.maker?.id,
            maker_text: pin.makerText,
            text: pin.text,
          };
    });

    return [...productPinParams];
  }, [pins]);

  const resetPins = useCallback(() => {
    setPins([]);
    setDefaultPins();
    setPosition(null);
  }, [setPins, setDefaultPins, setPosition]);

  return {
    pins,

    addPin,
    deletePin,

    pinParams,
    resetPins,
    setPosition,
  };
};

export default useEditPins;
