import InputAttentionLabel from "@/components/InputAttentionLabel";
import { InputAttentionLabelVariant } from "@/components/InputAttentionLabel/types/InputAttentionLabelVariant";
import Maybe from "@/components/Maybe";
import { FC, ReactNode, PropsWithChildren } from "react";

interface Props {
  id: string;
  description?: ReactNode;
  help?: ReactNode;
  label?: string;
  error?: ReactNode;
  attention?: InputAttentionLabelVariant;
}

const FormUnit: FC<PropsWithChildren<Props>> = ({
  label,
  id,
  error,
  help,
  description,
  attention,
  children,
}) => {
  return (
    <div className="relative">
      <div className="grid gap-8">
        <Maybe condition={!!label}>
          <div className="grid">
            <div className="flex items-center gap-8">
              <label
                htmlFor={id}
                className="flex gap-8 items-center font-bold text-sm"
              >
                <span>{label}</span>
              </label>
              <Maybe condition={!!attention}>
                <InputAttentionLabel variant={attention} />
              </Maybe>
            </div>

            <Maybe condition={!!description}>
              <small className="block text-xs text-secondary">
                {description}
              </small>
            </Maybe>
          </div>
        </Maybe>

        <div>{children}</div>

        <Maybe condition={!!error}>
          <small className="block text-danger text-xs font-bold">{error}</small>
        </Maybe>
      </div>

      <Maybe condition={!!help}>
        <div className="text-xs mt-4">{help}</div>
      </Maybe>
    </div>
  );
};
export default FormUnit;
