import { createSlice } from "@reduxjs/toolkit";

interface State {
  isOpen: boolean;
  redirectPath?: string;
}

const initialState = {
  isOpen: false,
  redirectPath: undefined,
} as State;

const slice = createSlice({
  name: "modalLimitFunction",
  initialState,
  reducers: {
    open(state) {
      state.isOpen = true;
    },
    close(state) {
      state.isOpen = false;
    },
    setRedirectPath(state, action) {
      state.redirectPath = action.payload;
    },
  },
});

export const { open, close, setRedirectPath } = slice.actions;
export const modalLimitFunctionReducer = slice.reducer;
