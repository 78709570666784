import { hideProgress, showProgress } from "@/stores/progress";
import { DefaultErrorMessage } from "@/domain/values/ErrorMessage";
import { useDispatch } from "react-redux";
import useSnackbar from "./useSnackbar";

interface Options {
  successMessage?: string;
  errorMessage?: string | { [key: number]: string };
}

type Progress = (
  process: () => Promise<void>,
  options?: Options,
) => Promise<void>;

const useProgress = (): Progress => {
  const dispatch = useDispatch();
  const { show: showSnackbar } = useSnackbar();

  return async (process, options) => {
    dispatch(showProgress());
    try {
      await process();

      if (options?.successMessage) {
        showSnackbar(options?.successMessage, "success");
      }
    } catch (e) {
      // エラーメッセージが設定されていない場合
      // エラーに入ってるメッセージを、入ってなければデフォルトメッセージを表示する
      if (!options?.errorMessage) {
        showSnackbar(e.message || DefaultErrorMessage, "error");

        return;
      }

      // 文字列で指定されている場合
      if (typeof options.errorMessage == "string") {
        showSnackbar(options?.errorMessage || DefaultErrorMessage, "error");

        return;
      }

      const status = e.response.status;
      const message = options.errorMessage[status];

      showSnackbar(message || DefaultErrorMessage, "error");
    } finally {
      dispatch(hideProgress());
    }
  };
};

export default useProgress;
