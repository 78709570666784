import { UserResponse } from "@/web-client";
import { FC } from "react";

interface Props {
  user: UserResponse;
}

const IconWithScreenName: FC<Props> = ({ user }): JSX.Element => {
  return (
    <div className="bg-gray-50 flex items-center space-x-8 p-8">
      <img
        src={user.icon.urls.small}
        alt={user.screen_name}
        className="w-48 h-48 rounded-full"
      />
      <p className="text-sm font-semibold">{user.screen_name}</p>
    </div>
  );
};

export default IconWithScreenName;
