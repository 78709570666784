import { InputAttentionLabelVariant } from "@/components/InputAttentionLabel/types/InputAttentionLabelVariant";
import { FC, useMemo } from "react";
import { tv } from "tailwind-variants";

interface Props {
  variant: InputAttentionLabelVariant;
}

const style = tv({
  base: "text-xs leading-none p-4 font-bold rounded-xxs",
  variants: {
    variant: {
      strongRequired: "text-danger bg-danger bg-opacity-5",
      required: "text-secondary bg-gray-50",
      optional: "text-secondary bg-gray-50",
    },
  },
});

const InputAttentionLabel: FC<Props> = ({ variant }) => {
  const label = useMemo(() => {
    switch (variant) {
      case "strongRequired":
      case "required":
        return "必須";
      case "optional":
        return "任意";
    }
  }, [variant]);

  return <small className={style({ variant })}>{label}</small>;
};
export default InputAttentionLabel;
