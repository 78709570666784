import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { SignUpBasicInputType } from "../types/SignUpBasicInputType";
import FormUnit from "@/components/FormUnit";
import {
  RequiredInputMessage,
  WrongPatternEmailMessage,
  WrongPatternPasswordMessage,
  getMaxLengthMessage,
  getMinLengthMessage,
} from "@/domain/values/InputErrorMessage";
import { EmailPattern, PasswordPattern } from "@/domain/values/InputPattern";
import { PasswordMinLength } from "../hooks/useSignUp";
import { useDispatch, useSelector } from "react-redux";
import useFirebaseAuth from "@/hooks/useFirebaseAuth";
import { RootState } from "@/stores/rootReducer";
import { setSignUpData } from "../stores/signUp";
import { AuthUser } from "@/stores/auth";
import Button from "@/components/Button";
import {
  DefaultErrorMessage,
  EmailAlreadyInUseErrorMessage,
} from "@/domain/values/ErrorMessage";
import useProgress from "@/hooks/useProgress";
import { MaxLengthOfScreenName } from "@/domain/values/MaxLengths";

interface Props {
  authUser?: AuthUser;
  onComplete?: () => void;
}

const UserBasicForm: FC<Props> = ({ authUser, onComplete }) => {
  const { isEmailAlreadyInUse } = useFirebaseAuth();
  const signUpStore = useSelector((state: RootState) => state.signUp);
  const progress = useProgress();
  const dispatch = useDispatch();

  const form = useForm<SignUpBasicInputType>({
    defaultValues: authUser
      ? { ...signUpStore, email: authUser.email }
      : signUpStore,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = form;

  const goToNextPage = useCallback(
    (value: SignUpBasicInputType) => {
      dispatch(setSignUpData(value));

      onComplete && onComplete();
    },
    [dispatch, onComplete],
  );

  const submitHandler = useCallback(
    async (value: SignUpBasicInputType) => {
      progress(
        async () => {
          if (authUser) {
            goToNextPage(value);

            return;
          }

          const result = await isEmailAlreadyInUse(value.email);

          if (result) {
            setError("email", { message: EmailAlreadyInUseErrorMessage });

            return;
          }

          goToNextPage(value);
        },
        { errorMessage: DefaultErrorMessage },
      );
    },
    [isEmailAlreadyInUse, goToNextPage, authUser, setError, progress],
  );

  return (
    <form
      onSubmit={handleSubmit(submitHandler)}
      className="flex flex-col space-y-40"
    >
      <div className="grid gap-24">
        <div className="grid gap-16">
          <FormUnit
            id="screen_name"
            label="TECTUREでの表示名"
            description="TECTURE上で他のユーザーにも公開される情報です"
            error={errors.screen_name?.message || ""}
            attention="required"
          >
            <input
              type="text"
              {...register("screen_name", {
                required: RequiredInputMessage,
                maxLength: {
                  value: MaxLengthOfScreenName,
                  message: getMaxLengthMessage(MaxLengthOfScreenName),
                },
              })}
              autoComplete="name"
              className="w-full"
              placeholder="テクチャーくん"
            />
          </FormUnit>

          <FormUnit
            id="email"
            label="メールアドレス"
            error={errors.email?.message || ""}
            attention="required"
          >
            <input
              type="email"
              disabled={!!authUser}
              {...register("email", {
                required: RequiredInputMessage,
                pattern: {
                  value: EmailPattern,
                  message: WrongPatternEmailMessage,
                },
              })}
              autoComplete="email"
              className="w-full"
              placeholder="mail@sample.com"
            />
          </FormUnit>

          {!authUser && (
            <FormUnit
              id="password"
              label="パスワード"
              description="半角英数字のみ・8文字以上"
              error={errors.password?.message || ""}
              attention="required"
            >
              <input
                type="password"
                {...register("password", {
                  required: RequiredInputMessage,
                  pattern: {
                    value: PasswordPattern,
                    message: WrongPatternPasswordMessage,
                  },
                  minLength: {
                    value: PasswordMinLength,
                    message: getMinLengthMessage(PasswordMinLength),
                  },
                })}
                className="w-full"
                autoComplete="new-password"
              />
            </FormUnit>
          )}
        </div>
      </div>

      <Button type="submit">次へ</Button>
    </form>
  );
};

export { UserBasicForm };
