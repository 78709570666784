import { RootState } from "@/stores/rootReducer";
import { AuthProvider } from "@/domain/values/AuthProvider";
import { useMemo } from "react";
import { useSelector } from "react-redux";

interface UseAuthProviderReturn {
  passwordChangeEnabled: boolean;
  emailChangeEnabled: boolean;
  signUpMethod: AuthProvider | undefined;
}

const useAuthProvider = (): UseAuthProviderReturn => {
  const { authUser } = useSelector((state: RootState) => state.auth);

  const signUpMethod = useMemo<AuthProvider | undefined>(() => {
    if (!authUser) return undefined;

    if (authUser.providers.includes(AuthProvider.Password)) {
      return AuthProvider.Password;
    }

    if (authUser.providers.includes(AuthProvider.Google)) {
      return AuthProvider.Google;
    }

    return undefined;
  }, [authUser]);

  const passwordChangeEnabled = useMemo<boolean>(() => {
    if (!authUser) return false;

    return authUser.providers.includes(AuthProvider.Password);
  }, [authUser]);

  const emailChangeEnabled = useMemo<boolean>(() => {
    if (!authUser) return false;

    return !authUser.providers.includes(AuthProvider.Google);
  }, [authUser]);

  return {
    passwordChangeEnabled,
    emailChangeEnabled,
    signUpMethod,
  };
};

export default useAuthProvider;
