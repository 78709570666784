import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  OrderCreateParameterQuestionnaire,
  OrderWithGroupsResponse,
  ProductResponse,
  ProductVariationWithCatalogPagesResponse,
  ProductVariationWithProductResponse,
} from "@/web-client/api";
import { OrganizedSamples } from "@/hooks/useCart";

export type ProductVariationWithSample =
  | ProductVariationWithCatalogPagesResponse
  | ProductVariationWithProductResponse;

interface AdditionalElement {
  label: string;
  value: number;
}

export type SampleWithQuantity = {
  productVariation: ProductVariationWithSample;
  quantity: number;
  product: ProductResponse;
  additional_data?: AdditionalElement[];
  updated_at?: string;
  board_id?: number;
};

interface State {
  samples: SampleWithQuantity[];
  questionnaire: OrderCreateParameterQuestionnaire;
  order: OrderWithGroupsResponse;
}

const initialState = {
  samples: [],
} as State;

export const MaxQuantityOfSameMakerItems = 5;

export const checkQuantityOnRule = (
  organizedSamples: OrganizedSamples[],
): void => {
  const makers = organizedSamples.filter(
    (o) =>
      o.samples.reduce((acc, cur) => acc + cur.quantity, 0) >
      MaxQuantityOfSameMakerItems,
  );

  if (makers.length > 0) {
    throw Error(
      `${makers
        .map((m) => m.maker.name)
        .join(
          ",",
        )}のサンプルの個数が上限を超えています。\n恐れ入りますが、同じメーカーのサンプルの個数を${MaxQuantityOfSameMakerItems}個以下にご変更ください。`,
    );
  }
};

const slice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addSample(state, action: PayloadAction<SampleWithQuantity>) {
      state.samples = [...state.samples, action.payload];
    },
    // 指定のサンプルをカートから削除する
    removeSample(
      state,
      action: PayloadAction<ProductVariationWithCatalogPagesResponse>,
    ) {
      const sample = action.payload;
      const index = state.samples.findIndex(
        (item) => item.productVariation.id === sample.id,
      );

      if (index !== -1) {
        state.samples.splice(index, 1);
      }
    },
    // カート内のサンプルの個数を指定する
    changeOrderCountOfSample(
      state,
      action: PayloadAction<{
        productVariation: ProductVariationWithSample;
        product: ProductResponse;
        quantity: number;
        updated_at?: string;
      }>,
    ) {
      const { productVariation, quantity, updated_at } = action.payload;

      const item = state.samples.find(
        (itemInCart) => itemInCart.productVariation.id === productVariation.id,
      );

      if (item) {
        item.quantity = quantity;
        item.updated_at = updated_at;
      }
    },
    clearAllSamples(state) {
      state.samples = [];
    },
    setQuestionnaire(
      state,
      action: PayloadAction<OrderCreateParameterQuestionnaire>,
    ) {
      state.questionnaire = action.payload;
    },
    clearQuestionnaire(state) {
      state.questionnaire = null;
    },
    setOrder(state, action: PayloadAction<OrderWithGroupsResponse>) {
      state.order = action.payload;
    },
    clearOrder(state) {
      state.order = null;
    },
  },
});

export const {
  addSample,
  changeOrderCountOfSample,
  removeSample,
  clearAllSamples,
  setQuestionnaire,
  clearQuestionnaire,
  setOrder,
  clearOrder,
} = slice.actions;
export const cartReducer = slice.reducer;
