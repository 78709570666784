import axios from "axios";
import { DefaultApi } from "@/web-client/api";
import { Signature } from "@/utils/api/signature";
import { Configuration } from "@/web-client/configuration";
import { API_KEY, BASE_URL } from "@/config/env";

const signature = new Signature(API_KEY);

axios.defaults.headers["x-tecture-timestamp"] = signature.time;
axios.defaults.headers["x-tecture-signature"] = signature.value;

const client = new DefaultApi(new Configuration({ basePath: BASE_URL }));
export default client;
