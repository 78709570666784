import client from "@/utils/api/client";
import { useDispatch, useSelector } from "react-redux";
import { setUser, setIsAuthenticated } from "@/stores/auth";
import { useCallback, useMemo } from "react";
import { DefaultErrorMessage } from "@/domain/values/ErrorMessage";
import { DesignerCreateParam, UserCreateParam } from "@/web-client/api";
import { RootState } from "@/stores/rootReducer";
import useAnalytics from "@/hooks/useAnalytics";
import useFirebaseAuth from "@/hooks/useFirebaseAuth";
import useAuthProvider from "@/hooks/useAuthProvider";
import { SignUpType } from "../types/SignUpType";

export const PasswordMinLength = 8;

type SignUpHandler = (value: SignUpType, asPro: boolean) => Promise<void>;

export type UseSignUpResponse = {
  signUp: SignUpHandler;
  hasAuthUser: boolean;
};

const useSignUp = (): UseSignUpResponse => {
  const { authUser } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const { event } = useAnalytics();
  const { createUserWithEmailAndPassword } = useFirebaseAuth();
  const { signUpMethod } = useAuthProvider();

  const hasAuthUser = useMemo(() => !!authUser, [authUser]);

  const signUp = useCallback<SignUpHandler>(
    async (input, asPro) => {
      if (!hasAuthUser) {
        await createUserWithEmailAndPassword(input.email, input.password);
      }

      if (asPro) {
        const { data } = await client
          .designersPost({ designerCreateParam: input as DesignerCreateParam })
          .catch(() => {
            throw new Error(DefaultErrorMessage);
          });
        dispatch(setUser(data));
      } else {
        const { data } = await client
          .usersPost({ userCreateParam: input as UserCreateParam })
          .catch(() => {
            throw new Error(DefaultErrorMessage);
          });
        dispatch(setUser(data));
      }

      dispatch(setIsAuthenticated(true));

      event("sign_up", { method: signUpMethod });
    },
    [
      dispatch,
      event,
      createUserWithEmailAndPassword,
      hasAuthUser,
      signUpMethod,
    ],
  );

  return {
    signUp,
    hasAuthUser,
  };
};

export { useSignUp };
