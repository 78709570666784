import useDisclosure from "@/hooks/useDisclosure";
import useProgress from "@/hooks/useProgress";
import dynamic from "next/dynamic";
import { ComponentType, useCallback } from "react";
import { requestJoinTeam } from "@/features/team";

interface UseRequestJoinTeamReturn {
  call: () => void;
  Dialog: ComponentType;
}

const DinaymicDialogCompleteJoinRequestTeam = dynamic(() =>
  import("@/features/team").then((mod) => mod.DialogCompleteJoinRequestTeam),
);

export const useRequestJoinTeam = (
  teamId: number,
  onClosed?: () => void,
): UseRequestJoinTeamReturn => {
  const progress = useProgress();
  const { isOpen, closeHandler, openHandler } = useDisclosure();
  const closeHandlerWithCallback = useCallback(() => {
    closeHandler();
    onClosed?.();
  }, [closeHandler, onClosed]);

  const call = useCallback(() => {
    progress(async () => {
      await requestJoinTeam(teamId);
      openHandler();
    });
  }, [progress, teamId, openHandler]);

  const Dialog = useCallback(
    () => (
      <DinaymicDialogCompleteJoinRequestTeam
        isOpen={isOpen}
        close={closeHandlerWithCallback}
      />
    ),
    [isOpen, closeHandlerWithCallback],
  );

  return {
    call,
    Dialog,
  };
};
