import { useCallback, useMemo } from "react";
import {
  createUserWithEmailAndPassword as createUserWithEmailAndPasswordFirebase,
  signInWithEmailAndPassword as signInWithEmailAndPasswordFirebase,
  getAuth,
  sendEmailVerification,
  UserCredential,
  GoogleAuthProvider,
  signInWithPopup,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import {
  DefaultErrorMessage,
  EmailAlreadyInUseErrorMessage,
  WrongUserAuthInputErrorMessage,
} from "@/domain/values/ErrorMessage";

interface UseFirebaseAuthReturn {
  createUserWithEmailAndPassword: (
    email: string,
    password: string,
  ) => Promise<void>;
  signInWithEmailAndPassword: (
    email: string,
    password: string,
  ) => Promise<void>;
  signInWithGoogle: () => Promise<boolean>;

  isEmailAlreadyInUse: (email: string) => Promise<boolean>;
}

const useFirebaseAuth = (): UseFirebaseAuthReturn => {
  const auth = useMemo(() => getAuth(), []);

  const checkCredential = useCallback(
    async (credential: UserCredential, sendEmail = false) => {
      if (!credential || !credential.user) {
        throw new Error(DefaultErrorMessage);
      }

      if (sendEmail) {
        await sendEmailVerification(credential.user).catch(() => {
          throw new Error(DefaultErrorMessage);
        });
      }
    },
    [],
  );

  const createUserWithEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      const credential = await createUserWithEmailAndPasswordFirebase(
        auth,
        email,
        password,
      ).catch((error: { code: string }) => {
        let message = "";
        switch (error.code) {
          case "auth/email-already-in-use":
            message = EmailAlreadyInUseErrorMessage;
            break;

          default:
            message = DefaultErrorMessage;
            break;
        }
        throw new Error(message);
      });

      await checkCredential(credential, true);
    },
    [checkCredential, auth],
  );

  const signInWithEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      const credential = await signInWithEmailAndPasswordFirebase(
        auth,
        email,
        password,
      ).catch((error: { code: string }) => {
        let message = "";
        switch (error.code) {
          case "auth/user-not-found":
          case "auth/wrong-password":
            message = WrongUserAuthInputErrorMessage;
            break;

          default:
            message = DefaultErrorMessage;
            break;
        }

        throw new Error(message);
      });

      checkCredential(credential);
    },
    [checkCredential, auth],
  );

  const signInWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();

    const result = await signInWithPopup(getAuth(), provider).catch(
      (error: { code: string }) => {
        switch (error.code) {
          case "auth/cancelled-popup-request":
          case "auth/popup-closed-by-user":
            break;

          default:
            throw new Error(DefaultErrorMessage);
        }
      },
    );

    return !!result;
  }, []);

  const isEmailAlreadyInUse = useCallback(
    async (email: string) => {
      try {
        const providers = await fetchSignInMethodsForEmail(auth, email);

        return providers.length > 0;
      } catch (error) {
        throw error;
      }
    },
    [auth],
  );

  return {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithGoogle,
    isEmailAlreadyInUse,
  };
};

export default useFirebaseAuth;
