import Dialog from "@/components/Dialog";
import { FC } from "react";

interface Props {
  isOpen: boolean;
  close: () => void;
}

const DialogCompleteJoinRequestTeam: FC<Props> = ({
  isOpen,
  close,
}): JSX.Element => {
  return (
    <Dialog isOpen={isOpen} onClose={close} title="参加申請完了">
      <p className="text-sm">
        チーム参加申請が送信されました。チームオーナーが申請を確認し、承認されるとメールでお知らせいたします。
      </p>
    </Dialog>
  );
};

export { DialogCompleteJoinRequestTeam };
