import { combineReducers } from "@reduxjs/toolkit";
import { authReducer } from "./auth";
import { cartReducer } from "./cart";
import { historyReducer } from "./history";
import { progressReducer } from "./progress";
import { snackbarReducer } from "./snackbar";
import { modalLimitFunctionReducer } from "./modalLimitFunction";
import { signUpReducer } from "@/features/user_registration";
import { joinRequestReducer } from "@/features/team";

const rootReducer = combineReducers({
  auth: authReducer,
  history: historyReducer,
  progress: progressReducer,
  snackbar: snackbarReducer,
  cart: cartReducer,
  modalLimitFunction: modalLimitFunctionReducer,
  signUp: signUpReducer,
  joinRequest: joinRequestReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
